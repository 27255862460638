import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
import { YoutubeItemTypes } from "./types";
import styled from "styled-components";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Arrow } from "./styled.components";
import Slider from "react-slick";

const Iframe = styled.iframe`
  width: 100% !important;
  margin: 0 !important;
  margin-bottom: 1rem !important;
`;

const VideoItem = styled.div`
  max-width: 350px;
  width: 100%;
  height: 275px;
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  text-align: center;
  margin: auto !important;

  @media (min-width: 768px) {
    height: 410px;
    max-width: 700px;
  }
`;

interface YoutubeSliderProps {
  data: YoutubeItemTypes[];
  playlistId?: string;
}

export default function YoutubeSlider({
  data,
  playlistId,
}: YoutubeSliderProps) {
  const [youtubeItems, setYoutubeItems] = useState<YoutubeItemTypes[]>(data);
  const [visitedSlide] = useState<number[]>([0]);
  const [currentIdx, setCurrentIdx] = useState<number>(1);
  console.log(visitedSlide);

  useEffect(() => {
    //   get the youtube playlist
    handleRequest();
  }, [playlistId]);

  async function handleRequest() {
    const playlist_id = playlistId;

    const youtube_api = process.env.REACT_APP_YOUTUBE_API;
    const video_list = `https://www.googleapis.com/youtube/v3/playlistItems?part=snippet&maxResults=50&playlistId=${playlist_id}&fields=items(snippet(resourceId%2FvideoId%2Cthumbnails%2Fmedium%2Furl%2Ctitle))&key=${youtube_api}`;

    const { data } = await axios.get(video_list);

    const filteredData: YoutubeItemTypes[] = [];

    data.items.map((item: any) => {
      filteredData.push({
        videoId: item.snippet.resourceId.videoId,
        title: item.snippet.title,
      });
    });

    setYoutubeItems(filteredData);
  }

  const ArrowPrev = (props: any) => {
    const { onClick } = props;

    return (
      <Arrow type="button" onClick={onClick} left="0%" className="from-lef">
        <FontAwesomeIcon icon={faAngleLeft as any} />
      </Arrow>
    );
  };

  const ArrowNext = (props: any) => {
    const { onClick } = props;
    return (
      <Arrow type="button" onClick={onClick} right="0%" className="from-righ">
        <FontAwesomeIcon icon={faAngleRight as any} />
      </Arrow>
    );
  };

  const items = youtubeItems.map((item, idx) => (
    <VideoItem key={idx}>
      {visitedSlide.includes(idx) && (
        <Iframe
          className="w-full h-full"
          src={`https://www.youtube-nocookie.com/embed/${item.videoId}`}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      )}
      <span className="text-[5vw] md:text-[1.4vw]">
        <h2 className="font-bold font-gotham-black italic text-ellipsis  whitespace-nowrap w-full block">
          {item.title}
        </h2>
        <a
          target="_blank"
          rel="noreferrer"
          href={`https://www.youtube.com/watch?v=${item.videoId}`}
          // href={item.stream}
          className="uppercase font-calibre-medium underline"
        >
          watch now
        </a>
      </span>
    </VideoItem>
  ));

  return (
    <div className="relative overflow-visible">
      <Slider
        nextArrow={<ArrowNext />}
        prevArrow={<ArrowPrev />}
        beforeChange={(currentIdx, next) => {
          if (!visitedSlide.some((vi) => vi === next)) {
            visitedSlide.push(next);
          }

          setCurrentIdx(currentIdx);
          console.log(visitedSlide);
        }}
      >
        {items}
      </Slider>
    </div>
  );
}
