import { faXmarkCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";

interface PopupProps {
  className?: string;
  children?: any;
}

const Popup = ({ className, children }: PopupProps) => {
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    setTimeout(() => {
      setOpen(true);
    }, 1000);
  }, []);

  return (
    <div
      className={`flex left-0 fixed w-full h-screen transition-all px-[5vw] ${
        open ? "top-0 opacity-100" : "-top-[120%] opacity-0"
      }`}
      style={{ zIndex: 123123123123 }}
    >
      <div className="relative bg-white m-auto rounded-md overflow-hidden">
        <button
          className="absolute top-5 left-5"
          onClick={() => setOpen(false)}
        >
          <FontAwesomeIcon icon={faXmarkCircle} fontSize="1.5rem" />
        </button>
        {children}
      </div>
    </div>
  );
};

export default Popup;
