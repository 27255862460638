import styled from "styled-components";
import { RoutePattern } from "../../routes/RoutePattern";
import { motion } from "framer-motion";
import IMG_1 from "../../assets/images/dlow-tour-flier-3x4.png";
import useScriptService from "../../hooks/useScriptService";
import LayloSignup from "./LayloSignup";
import LayloSignup2 from "./LayloSignup2";

export const TourImage = styled(motion.a)`
  transition: all 0.3s ease;
  overflow: hidden;
  text-align: right;
  width: 30vw;

  /*:hover img {
    transform: scale(1.2);
    transition: all 0.3s ease;
  }*/

  @media (max-width: 768px) {
    width: 80vw;
  }
`;

const Tour = () => {
  useScriptService({ url: "https://widgetv3.bandsintown.com/main.min.js" });

  return (
    <div
      id={RoutePattern.TOUR}
      className="min-h-screen font-secondary w-full pt-[14vh] md:px-[10vw] z-10 flex flex-col justify-center items-center"
    >
      <h2 className="text-center leading-[100%] text-[10vh] mb-[24px] px-2 md:px-5 uppercase tracking-[2px]">
        Tour
      </h2>
      <LayloSignup2 />
      <div className="w-full flex flex-col justify-center items-center px-2 md:px-5">
        {/* <TourImage>
          <img
            src={IMG_1}
            alt="ffm image"
            className="transition-all w-full h-full object-contain"
          />
        </TourImage>
        <div className="w-full mt-[4vh]">
           {" "}
          <a
            className="bit-widget-initializer"
            data-artist-name="id_15540989"
            data-background-color="#000"
            data-separator-color="#DDDDDD"
            data-text-color="#fff"
            data-font="Birch Std"
            data-auto-style="true"
            data-button-label-capitalization="capitalize"
            data-header-capitalization="undefined"
            data-location-capitalization="capitalize"
            data-venue-capitalization="capitalize"
            data-display-local-dates="true"
            data-local-dates-position="tab"
            data-display-past-dates="true"
            data-display-details=""
            data-display-lineup=""
            data-display-start-time=""
            data-social-share-icon="true"
            data-display-limit="all"
            data-date-format="MMM. D, YYYY"
            data-date-orientation="horizontal"
            data-date-border-color="#4A4A4A"
            data-date-border-width="1px"
            data-date-capitalization="undefined"
            data-date-border-radius="10px"
            data-event-ticket-cta-size="medium"
            data-event-custom-ticket-text="undefined"
            data-event-ticket-text="TICKETS"
            data-event-ticket-icon=""
            data-event-ticket-cta-text-color="#FFFFFF"
            data-event-ticket-cta-bg-color="#4A4A4A"
            data-event-ticket-cta-border-color="#4A4A4A"
            data-event-ticket-cta-border-width="0px"
            data-event-ticket-cta-border-radius="4px"
            data-sold-out-button-text-color="#FFFFFF"
            data-sold-out-button-background-color="#4A4A4A"
            data-sold-out-button-border-color="#4A4A4A"
            data-sold-out-button-clickable="true"
            data-event-rsvp-position="left"
            data-event-rsvp-cta-size="medium"
            data-event-rsvp-only-show-icon="undefined"
            data-event-rsvp-text="REMIND ME"
            data-event-rsvp-icon=""
            data-event-rsvp-cta-text-color="#4A4A4A"
            data-event-rsvp-cta-bg-color="#FFFFFF"
            data-event-rsvp-cta-border-color="#4A4A4A"
            data-event-rsvp-cta-border-width="1px"
            data-event-rsvp-cta-border-radius="4px"
            data-follow-section-position="top"
            data-follow-section-alignment="center"
            data-follow-section-header-text="Get updates on new shows, new music, and more."
            data-follow-section-cta-size="medium"
            data-follow-section-cta-text="FOLLOW"
            data-follow-section-cta-icon="true"
            data-follow-section-cta-text-color="#FFFFFF"
            data-follow-section-cta-bg-color="#4A4A4A"
            data-follow-section-cta-border-color="#4A4A4A"
            data-follow-section-cta-border-width="0px"
            data-follow-section-cta-border-radius="4px"
            data-play-my-city-position="bottom"
            data-play-my-city-alignment="Center"
            data-play-my-city-header-text="Don’t see a show near you?"
            data-play-my-city-cta-size="medium"
            data-play-my-city-cta-text="REQUEST A SHOW"
            data-play-my-city-cta-icon="true"
            data-play-my-city-cta-text-color="#FFFFFF"
            data-play-my-city-cta-bg-color="#4A4A4A"
            data-play-my-city-cta-border-color="#4A4A4A"
            data-play-my-city-cta-border-width="0px"
            data-play-my-city-cta-border-radius="4px"
            data-optin-font=""
            data-optin-text-color=""
            data-optin-bg-color=""
            data-optin-cta-text-color=""
            data-optin-cta-bg-color=""
            data-optin-cta-border-width=""
            data-optin-cta-border-radius=""
            data-optin-cta-border-color=""
            data-language="en"
            data-layout-breakpoint="900"
            data-app-id=""
            data-affil-code=""
            data-bit-logo-position="bottomRight"
            data-bit-logo-color="#CCCCCC"
          />
        </div> */}
      </div>
    </div>
  );
};

export default Tour;
