import Popup from "../components/Popup/Popup";
import { data } from "./Home/Music";

export default function AlbumPopup() {
  return (
    <div>
      <Popup>
        <div className="flex flex-col justify-center items-center gap-[1vh] pb-[1vw]">
          <img src={data[0].img} alt="ffm image" className="h-full mx-auto " />
          <a
            className="btn"
            href={data[0].link}
            target="_blank"
            rel="noopener noreferrer"
          >
            Out Now
          </a>
        </div>
      </Popup>
    </div>
  );
}
