import useScriptService from "../../hooks/useScriptService";

export default function LayloSignup() {
  useScriptService({
    url: "https://embed.laylo.com/laylo-sdk.js",
  });

  return (
    <div className="font-secondary w-full pt-[14vh] md:px-[10vw] z-20 flex flex-col justify-center items-center">
      <iframe
        id="laylo-drop-jqqbr"
        allow="web-share"
        src="https://embed.laylo.com?dropId=jqqbr&color=908c89&minimal=false&theme=dark&background=transparent"
      />
    </div>
  );
}
