import { IframePlaylist } from "../../components";
import { VideoIframe } from "../../components/IframePlaylist/types";
import YoutubeSlider from "../../components/YoutubeSlider/YoutubeSlider";
import { RoutePattern } from "../../routes/RoutePattern";

const data: VideoIframe[] = [
  {
    title: "BossMan Dlow - The Biggest Pt. 2 (No Skit)",
    videoId: "vmJq_83ng9M",
  },
  {
    title: "BossMan Dlow - PJ ft. Lil Baby",
    videoId: "7YA_b1_DGVU",
  },
  {
    title: "What You Need Ft. NoCap",
    videoId: "ndsMq4MJE1M",
  },
];

const Videos = () => {
  return (
    <div
      id={RoutePattern.VIDEOS}
      className="font-secondary w-full pt-[14vh] md:px-[10vw] relative z-20 flex flex-col justify-center items-center"
    >
      <h2 className="text-center leading-[100%] text-[10vh] mb-[24px] px-2 md:px-5 uppercase tracking-[2px]">
        Videos
      </h2>
      <div className="w-full flex justify-center items-center px-2 md:px-5">
        <div className="w-full">
          <YoutubeSlider
            data={data}
            // playlistId="PLDevsygPpfDoWi2V0cgI_71Jqq1r0XFiB"
          />
        </div>
      </div>
    </div>
  );
};

export default Videos;
