import useScriptService from "../../hooks/useScriptService";

export default function LayloSignup2() {
  useScriptService({
    url: "https://embed.laylo.com/laylo-sdk.js",
  });

  return (
    <div className="font-secondary w-full pt-[14vh] md:px-[10vw] z-20 flex flex-col justify-center items-center">
      <iframe
        id="laylo-drop-d2cb03ca-7219-4aab-aa6b-4e2aea0f3207"
        allow="web-share"
        src="https://embed.laylo.com/?dropId=d2cb03ca-7219-4aab-aa6b-4e2aea0f3207&color=b6bdce&minimal=false&theme=light"
      />
    </div>
  );
}
