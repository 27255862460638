import styled from "styled-components";
import { ArrowTypes } from "./types";

export const Arrow = styled.button<ArrowTypes>`
  position: absolute;
  z-index: 2;
  top: 100%;
  width: 40px;
  height: 40px;
  cursor: pointer;
  font-size: 2rem;
  font-weight: 100;
  outline: none;
  color: white;
  :focus {
    outline: none;
  }

  right: ${(props) => (props.right ? "35%" : "inherit")};
  left: ${(props) => (props.left ? "35%" : "inherit")};

  @media (min-width: 768px) {
    top: 35%;
    right: ${(props) => props.right && props.right};
    left: ${(props) => props.left && props.left};
  }
`;
